import React from 'react'
import { Helmet } from 'react-helmet'

import { mapArray } from '../helpers.js'

import favicon from '../static/favicon.png'
import ogLogo from '../assets/storytimeOG.jpg'

export class MainMetadataHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const homePageTitle = 'Storytime by RNZ'
    const homePageDescription =
      'Storytime: RNZ’s collection of free children’s audio books. All written and produced in  New Zealand for little kids, kids and young adults.'
    const homePageUrl = this.props.canonicalUrl
    const ogLogoUrl =
      ogLogo.indexOf(this.props.canonicalUrl) !== -1
        ? ogLogo
        : this.props.canonicalUrl + ogLogo
    const ogLogoAlt = 'Storytime website logo'
    return (
      <Helmet
        title={homePageTitle}
        meta={[
          {
            name: 'description',
            content: homePageDescription,
          },
          {
            name: 'author',
            content: 'RNZ',
          },
          {
            name: 'keywords',
            content:
              'rnz, radionewzealand, radio, new, zealand, audiobooks, children, youngadult',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
          {
            name: 'google-site-verification',
            content: 'cd1-VfMEiZtmyM-psSOxEV1TTKspG3fg0b0a_l-ZXkE',
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:site',
            content: '@radionz',
          },
          {
            name: 'twitter:creator',
            content: '@radionz',
          },
          {
            name: 'twitter:title',
            content: homePageTitle,
          },
          {
            name: 'twitter:description',
            content: homePageDescription,
          },
          {
            name: 'twitter:image',
            content: ogLogoUrl,
          },
          {
            name: 'twitter:image:alt',
            content: ogLogoAlt,
          },
          {
            property: 'og:locale',
            content: 'en_NZ',
          },
          {
            property: 'og:site_name',
            content: 'Storytime',
          },
          {
            property: 'og:url',
            content: homePageUrl,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:title',
            content: homePageTitle,
          },
          {
            property: 'og:description',
            content: homePageDescription,
          },
          {
            property: 'og:image',
            content: ogLogoUrl,
          },
          {
            property: 'og:image:alt',
            content: ogLogoAlt,
          },
          {
            property: 'fb:app_id',
            content: '1138504109506300',
          },
        ]}
        link={[
          { rel: 'shortcut icon', type: 'image/png', href: favicon },
          { rel: 'canonical', href: homePageUrl },
        ]}>
        <html lang="en" />
      </Helmet>
    )
  }
}

export class BookMetadataHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const bookPageTitle = `${this.props.book.title} on Storytime by RNZ`
    const bookPageDescription = this.props.book.book_synopsis
    const bookPageUrl = `${this.props.canonicalUrl}/${this.props.book.gatsby_slug}/`
    const bookPageImageAlt = `Cover of ${this.props.book.title}`
    return (
      <Helmet
        title={bookPageTitle}
        meta={[
          {
            name: 'description',
            content: bookPageDescription,
          },
          {
            name: 'author',
            content: mapArray(this.props.book.book_author),
          },
          {
            name: 'twitter:creator',
            content: '',
          },
          {
            name: 'twitter:title',
            content: bookPageTitle,
          },
          {
            name: 'twitter:description',
            content: bookPageDescription,
          },
          {
            name: 'twitter:image',
            content: this.props.book.image,
          },
          {
            name: 'twitter:image:alt',
            content: bookPageImageAlt,
          },
          {
            property: 'og:url',
            content: bookPageUrl,
          },
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'og:title',
            content: bookPageTitle,
          },
          {
            property: 'og:description',
            content: bookPageDescription,
          },
          {
            property: 'og:image',
            content: this.props.book.image,
          },
          {
            property: 'og:image:alt',
            content: bookPageImageAlt,
          },
        ]}
        link={[{ rel: 'canonical', href: bookPageUrl }]}
      />
    )
  }
}

export class CategoryMetadataHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const categoryPageTitle = `${this.props.category} stories on Storytime by RNZ`
    const categoryPageDescription = `All our ${this.props.category} audio books, songs and stories in one place.`
    const categoryPageUrl = `${
      this.props.canonicalUrl
    }/topic/${this.props.category.toLowerCase()}/`
    return (
      <Helmet
        title={categoryPageTitle}
        meta={[
          {
            name: 'description',
            content: categoryPageDescription,
          },
          {
            name: 'twitter:creator',
            content: '',
          },
          {
            name: 'twitter:title',
            content: categoryPageTitle,
          },
          {
            name: 'twitter:description',
            content: categoryPageDescription,
          },
          {
            property: 'og:url',
            content: categoryPageUrl,
          },
          {
            property: 'og:type',
            content: 'article',
          },
          {
            property: 'og:title',
            content: categoryPageTitle,
          },
          {
            property: 'og:description',
            content: categoryPageDescription,
          },
        ]}
        link={[{ rel: 'canonical', href: categoryPageUrl }]}
      />
    )
  }
}
