import React from 'react'
import PropTypes from 'prop-types'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import '../fonts/font.scss'
import '../css/index.scss'
import MediaPlayer from '../components/mediaPlayer.js'
import { MainMetadataHeader } from '../components/metadataHeader.js'
import { StaticQuery, graphql } from 'gatsby'
import ReactGA from 'react-ga'

//FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faChevronDown,
  faChevronUp,
  faPlay,
  faPlayCircle,
  faVolumeUp,
  faTimesCircle,
  faTimes,
  faPause,
  faForward,
  faRedo,
  faBackward,
  faUndo,
  faShareAlt,
  faVolumeMute,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  faSearch,
  faChevronDown,
  faChevronUp,
  faPlay,
  faVolumeUp,
  faTimesCircle,
  faTimes,
  faPlayCircle,
  faPause,
  faForward,
  faBackward,
  faRedo,
  faUndo,
  faShareAlt,
  faVolumeMute
)

import layout from '../css/modules/layout.module.scss'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mediaPlaying: false,
      mediaSource: null,
      sourceNum: 0,
      mediaConfig: {},
      mediaTracks: {},
      mediaNo: 0,
      searchQuery: '',
      loading: true,
      playerExpanded: false,
    }
    this.mediaClick = this.mediaClick.bind(this)
    this.controlState = this.controlState.bind(this)
    this.onEnded = this.onEnded.bind(this)
    this.onPlayed = this.onPlayed.bind(this)
    this.onLoaded = this.onLoaded.bind(this)
    this.playerExpanded = this.playerExpanded.bind(this)
    this.searchQuery = this.searchQuery.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1)
  }

  mediaClick(e, mediaInfo = false, sourceNum = 0) {
    let sourceObj = ''
    if (Array.isArray(e)) {
      sourceObj = e.map(clip => ({
        src: clip.clip_link,
        type: 'audio/mp3',
        mediaInfo: {
          title: clip.title,
          image: clip.clip_image ? clip.clip_image : mediaInfo.image,
          author: mediaInfo.book_author,
        },
      }))
      this.startPlaying(sourceObj, sourceNum, mediaInfo)
    }
  }

  searchQuery(e) {
    this.setState({ searchQuery: e })
  }

  startPlaying(sourceObj, sourceNum) {
    if (this.state.mediaSource) {
      if (
        sourceObj[sourceNum].src ===
        this.state.mediaSource[this.state.sourceNum].src
      )
        return
    }
    this.setState({ mediaSource: sourceObj, sourceNum: sourceNum })
    this.setState({ mediaPlaying: true })
  }

  onEnded(e) {
    ReactGA.ga(
      'send',
      'event',
      'Listen Duration',
      `${this.state.mediaSource[this.state.sourceNum].mediaInfo.title} - Ended`
    )
    if (this.state.sourceNum + 1 < this.state.mediaSource.length) {
      this.setState({ sourceNum: this.state.sourceNum + 1 })
    }
  }

  onPlayed(e) {
    if (this.state.onPlay === false) {
      this.setState({ onPlay: true })
      return
    }
    this.setState({ onPlay: false })
  }

  onLoaded(e) {
    this.setState({ onPlay: true })
  }

  playerExpanded() {
    this.setState({ playerExpanded: !this.state.playerExpanded })
  }

  controlState(e) {
    switch (e) {
      case 'play':
        this.setState({ mediaPlaying: true, onPlay: false })
        break
      case 'pause':
        this.setState({ mediaPlaying: false })
        break
      case 'mute':
        this.setState({ mediaMuted: true })
        break
      case 'unmute':
        this.setState({ mediaMuted: false })
        break
      case 'next':
        if (this.state.sourceNum + 1 === this.state.mediaSource.length) return
        this.setState({
          sourceNum: this.state.sourceNum + 1,
          mediaPlaying: true,
        })
        break
      case 'back':
        if (this.state.sourceNum === 0) return
        this.setState({
          sourceNum: this.state.sourceNum - 1,
          mediaPlaying: true,
        })
        break
    }
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        ...this.props,
        layout: 'false',
        onClick: this.mediaClick,
        mediaPlaying: this.state.mediaPlaying,
        searchQuery: this.searchQuery,
        searchState: this.state.searchQuery,
        sources: this.state.mediaSource,
        sourceNum: this.state.sourceNum,
        onPlay: this.state.onPlay,
      })
    )

    return (
      <StaticQuery
        query={graphql`
          query canonicalUrlQuery {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `}
        render={data => (
          <div className={layout.wrapperPlayer}>
            <noscript>
              <div style={{ padding: '20px', maxWidth: '45em' }}>
                <h1 style={{ marginBottom: '0.5em' }}>
                  Javascript is not working.
                </h1>
                <p style={{ marginBottom: '0.5em' }}>
                  Storytime required JavaScript to display and play our stories.{' '}
                  <em>You current have JavaScript disabled in your browser</em>.
                  Please enable it and reload the page to browse Storytime.
                </p>
                <p>
                  If you are unsure how to enable JavaScript this website has
                  instructions for most browsers:{' '}
                  <a
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    href="https://www.wikihow.com/Enable-JavaScript">
                    How to enable JavaScript
                  </a>
                </p>
              </div>
            </noscript>
            <MainMetadataHeader canonicalUrl={data.site.siteMetadata.siteUrl} />
            <div className={layout.contentContainer}>
              <MediaPlayer
                id="player1"
                mediaType="audio"
                preload="none"
                controls
                width="640"
                height="360"
                poster="false"
                onClick={this.mediaClick}
                onPlayed={this.onPlayed}
                onPlayState={this.state.onPlay}
                onLoaded={this.onLoaded}
                controlState={this.controlState}
                onEnded={this.onEnded}
                sources={this.state.mediaSource}
                sourceNum={this.state.sourceNum}
                sourceNumHandler={this.sourceNumHandler}
                playing={this.state.mediaPlaying}
                playerExpanded={this.playerExpanded}
                expandState={this.state.playerExpanded}
                muted={this.state.mediaMuted}
                options={JSON.stringify(this.state.mediaTracks)}
                tracks={JSON.stringify(this.state.mediaConfig)}
              />
              <div
                style={{
                  opacity: this.state.loading
                    ? '0'
                    : this.state.playerExpanded
                    ? '0'
                    : '1',
                  transition: 'opacity 0.1s',
                  overflow: 'auto',
                }}>
                {childrenWithProps}
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.object,
}

export default Layout
