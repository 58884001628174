import React from 'react'
import { mediaTime } from '../helpers.js'
import css from '../css/modules/mediaPlayer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dotdotdot from 'react-dotdotdot'
import SVG from 'react-inlinesvg'
import Slider from 'rc-slider'
import '../css/rc-slider.min.scss'
import { window } from 'browser-monads'

import logo from '../static/rnz-new.png'
import playIcon from '../static/icons/PlaySVG.svg'
import pauseIcon from '../static/icons/PauseSVG.svg'
import fSkipIcon from '../static/icons/skipfwdSVG.svg'
import bSkipIcon from '../static/icons/skipbwdSVG.svg'
import nextIcon from '../static/icons/NextSVG.svg'
import backIcon from '../static/icons/PreviousSVG.svg'
import volIcon from '../static/icons/volumeOn.svg'
import muteIcon from '../static/icons/volumeOff.svg'
import xButton from '../static/icons/cross.svg'
import chevronDown from '../static/icons/chevronDown.svg'
import chevronUp from '../static/icons/chevronUp.svg'

export default class MediaPlayerControls extends React.Component {
  constructor(props) {
    super(props)
    this.actionClick = this.actionClick.bind(this)
    this.expandPlayer = this.expandPlayer.bind(this)
  }

  actionClick(e) {
    if (!this.props.onPlayState) return
    if (!this.props.playerLoaded) return
    this.props.mediaAction(e)
    if (e === '30f' || e === '30b' || e === 'back') return
    this.props.controlState(e)
  }

  mediaSeek(e) {
    if (!this.props.playerLoaded) return
    if (!this.props.onPlayState) return
    this.props.mediaSeek(e)
  }

  expandPlayer(e) {
    this.props.playerExpanded()
  }

  render() {
    return (
      <div
        style={{
          height: this.props.expandState ? `${window.innerHeight}px` : '',
        }}
        className={
          window.innerWidth > 767
            ? css.wrapper
            : this.props.expandState
            ? [css.wrapperExpand, 'wrapperExpand'].join(' ')
            : [css.wrapper, 'wrapper'].join(' ')
        }>
        <div
          style={{
            opacity: this.props.loading ? '0' : '1',
            transition: 'opacity 0.1s',
          }}
          className={css.container}>
          <div
            onClick={
              this.props.playerLoaded && window.innerWidth < 768
                ? e => this.expandPlayer(e)
                : e => null
            }>
            <div
              className={
                this.props.playerLoaded
                  ? css.expandIcon
                  : [css.expandIcon, css.inactiveExpand].join(' ')
              }>
              <SVG src={this.props.expandState ? chevronDown : chevronUp}></SVG>
            </div>
            {this.props.expandState ? (
              <div
                className={
                  this.props.playerLoaded
                    ? css.xIcon
                    : [css.xIcon, css.inactiveExpand].join(' ')
                }>
                <SVG src={xButton}></SVG>
              </div>
            ) : (
              ''
            )}
          </div>
          <img alt="RNZ logo" className={css.logo} src={logo} />

          <MediaInfo
            playerLoaded={this.props.playerLoaded}
            timeElapsed={this.props.timeElapsed}
            playerExpanded={this.props.expandState}
            mediaInfo={
              this.props.sources
                ? this.props.sources[this.props.sourceNum].mediaInfo
                : ''
            }
            timeRemaining={this.props.timeRemaining}
            onClick={
              this.props.playerLoaded &&
              !this.props.expandState &&
              window.innerWidth < 768
                ? e => this.expandPlayer(e)
                : ''
            }
          />

          <div className={css.progressWrapper}>
            <Slider
              defaultValue={100}
              value={this.props.progressIndex}
              min={0}
              max={1}
              step={0.0025}
              onChange={e => this.mediaSeek(e)}
              className={css.progressBack}
              handleStyle={{ opacity: this.props.playerLoaded ? '1' : '0' }}
            />
          </div>

          <div className={css.buttonRow}>
            <div className={css.buttonContainer}>
              <MediaPlayerButton
                className={
                  this.props.playerLoaded
                    ? css.skipButton
                    : [css.skipButton, css.inactiveButtons].join(' ')
                }
                onClick={e => this.actionClick('back')}
                icon={backIcon}
              />

              <MediaPlayerButton
                className={
                  this.props.playerLoaded
                    ? css.thirtyButton
                    : [css.inactiveButtons, css.thirtyButton].join(' ')
                }
                onClick={e => this.actionClick('30b')}
                icon={bSkipIcon}
              />

              <MediaPlayerButton
                className={
                  this.props.playerLoaded
                    ? css.play
                    : [css.inactiveButtons, css.play].join(' ')
                }
                onClick={e =>
                  this.actionClick(this.props.playing ? 'pause' : 'play')
                }
                icon={this.props.playing ? pauseIcon : playIcon}
              />

              <MediaPlayerButton
                className={
                  this.props.playerLoaded
                    ? css.muteButton
                    : [css.inactiveButtons, css.muteButton].join(' ')
                }
                onClick={e =>
                  this.actionClick(this.props.muted ? 'unmute' : 'mute')
                }
                icon={this.props.muted ? muteIcon : volIcon}
              />

              <MediaPlayerButton
                className={
                  this.props.playerLoaded
                    ? css.thirtyButton
                    : [css.inactiveButtons, css.thirtyButton].join(' ')
                }
                onClick={e => this.actionClick('30f')}
                icon={fSkipIcon}
              />

              <MediaPlayerButton
                className={
                  this.props.sources
                    ? this.props.sourceNum + 1 === this.props.sources.length
                      ? [css.skipButton, css.inactiveButtons].join(' ')
                      : css.skipButton
                    : [css.skipButton, css.inactiveButtons].join(' ')
                }
                onClick={e => this.actionClick('next')}
                icon={nextIcon}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class MediaInfo extends React.Component {
  render() {
    const playerClipIcon = this.props.playerLoaded
      ? this.props.mediaInfo.image
      : ''
    return (
      <div className={css.mediaInfo}>
        <div className={css.textbox}>
          <Dotdotdot clamp={1}>
            <p onClick={() => this.props.onClick} className={css.title}>
              {this.props.playerLoaded ? this.props.mediaInfo.title : ''}
            </p>
          </Dotdotdot>
          <Dotdotdot clamp={1}>
            <p className={css.author}>
              {this.props.playerLoaded ? this.props.mediaInfo.author : ''}
            </p>
          </Dotdotdot>
          <div
            style={{ display: this.props.playerLoaded ? 'unset' : 'none' }}
            className={css.timeBox}>
            <p className={css.timeRemaining}>
              {this.props.timeRemaining
                ? isNaN(this.props.timeRemaining)
                  ? ''
                  : '-' + mediaTime(this.props.timeRemaining)
                : '-0:00'}
            </p>
            <p className={css.timeElapsed}>
              {this.props.timeElapsed
                ? isNaN(this.props.timeElapsed)
                  ? ''
                  : mediaTime(this.props.timeElapsed)
                : '0:00'}
            </p>
          </div>
        </div>
        <div className={css.item}>
          <div className={css.background}>
            {playerClipIcon == '' ? (
              ''
            ) : (
              <div>
                <img alt="" className={css.blurbg} src={playerClipIcon} />
                <img
                  alt={`Cover of ${this.props.mediaInfo.title}`}
                  className={css.image}
                  src={playerClipIcon}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class MediaPlayerButton extends React.Component {
  render() {
    return (
      <div
        onClick={this.props.onClick}
        style={this.props.style}
        className={this.props.className ? this.props.className : css.buttons}>
        {this.props.icon ? (
          this.props.icon.substr(0, 4) === 'data' ? (
            <SVG src={this.props.icon}></SVG>
          ) : (
            <FontAwesomeIcon icon={this.props.icon} />
          )
        ) : (
          ''
        )}
        {this.props.children ? this.props.children : ''}
      </div>
    )
  }
}
