export function getProperty(propertyName, sortObject) {
  var parts = propertyName.split('.'),
    length = parts.length,
    i,
    sortProperty = sortObject || this
  for (i = 0; i < length; i++) {
    sortProperty = sortProperty[parts[i]]
  }
  return { sortObject, sortProperty }
}

export function dynamicSort(property) {
  var sortOrder = -1
  property = property.split('.')
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000)
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var hour = a.getHours()
  var min = a.getMinutes()
  var sec = a.getSeconds()
  var time =
    date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec
  return time
}

export function titleCase(title) {
  return title.charAt(0).toUpperCase() + title.substr(1)
}

export function lowerCase(title) {
  return title.charAt(0).toLowerCase() + title.substr(1)
}

export function humanTime(time) {
  var minutes = Math.floor(time / 60)
  var seconds = time - minutes * 60
  return `${minutes}m ${seconds}s`
}

export function mediaTime(s) {
  s = Math.floor(s)
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
}

export function mapArrayNoOxford(array, prop = 'title', join = ', and ') {
  return array
    .map(function(elem) {
      return elem[prop]
    })
    .join(', ')
}

export function mapArray(array, prop = 'title', join = ', and ') {
  let mappedArray = []
  array.map(function(elem, i) {
    mappedArray.push(elem[prop])
    if (array.length !== i + 1) {
      mappedArray.push(
        array.length === 2 && i === 0
          ? ' and '
          : array.length === i + 2
          ? ', and '
          : array.length === i + 1
          ? ''
          : ', '
      )
    }
  })
  return mappedArray.join('')
}

export function naturalSort(prop) {
  return function(a, b) {
    'use strict'
    var re = /(^([+\-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+\-]?\d+)?)?$|^0x[0-9a-f]+$|\d+)/gi,
      sre = /(^[ ]*|[ ]*$)/g,
      dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
      hre = /^0x[0-9a-f]+$/i,
      ore = /^0/,
      i = function(s) {
        return (naturalSort.insensitive && ('' + s).toLowerCase()) || '' + s
      },
      // convert all to strings strip whitespace
      x = i(a[prop]).replace(sre, '') || '',
      y = i(b[prop]).replace(sre, '') || '',
      // chunk/tokenize
      xN = x
        .replace(re, '\0$1\0')
        .replace(/\0$/, '')
        .replace(/^\0/, '')
        .split('\0'),
      yN = y
        .replace(re, '\0$1\0')
        .replace(/\0$/, '')
        .replace(/^\0/, '')
        .split('\0'),
      // numeric, hex or date detection
      xD =
        parseInt(x.match(hre), 16) ||
        (xN.length !== 1 && x.match(dre) && Date.parse(x)),
      yD =
        parseInt(y.match(hre), 16) ||
        (xD && y.match(dre) && Date.parse(y)) ||
        null,
      oFxNcL,
      oFyNcL
    // first try and sort Hex codes or Dates
    if (yD) {
      if (xD < yD) {
        return -1
      } else if (xD > yD) {
        return 1
      }
    }
    // natural sorting through split numeric strings and default strings
    for (
      var cLoc = 0, numS = Math.max(xN.length, yN.length);
      cLoc < numS;
      cLoc++
    ) {
      // find floats not starting with '0', string or 0 if not defined (Clint Priest)
      oFxNcL =
        (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0
      oFyNcL =
        (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0
      // handle numeric vs string comparison - number < string - (Kyle Adams)
      if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
        return isNaN(oFxNcL) ? 1 : -1
      }
      // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
      else if (typeof oFxNcL !== typeof oFyNcL) {
        oFxNcL += ''
        oFyNcL += ''
      }
      if (oFxNcL < oFyNcL) {
        return -1
      }
      if (oFxNcL > oFyNcL) {
        return 1
      }
    }
    return 0
  }
}
