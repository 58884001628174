import React from 'react'
import MediaPlayerControls from '../components/mediaPlayerControls.js'
import ReactGA from 'react-ga'

import player from '../css/modules/mediaPlayer.module.scss'

// Import stylesheet and shims
var thePlayer

export default class MediaPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.mediaAction = this.mediaAction.bind(this)
    this.mediaSeek = this.mediaSeek.bind(this)
    this.onTimeUpdate = this.onTimeUpdate.bind(this)
    this.state = {
      progressIndex: 0,
      playerLoaded: false,
      playedEighty: false,
      loading: true,
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.sources) return
    if (!prevProps.sources) {
      this.setState({ playerLoaded: true, playedEighty: false })
      this.state.player.setSrc(this.props.sources[this.props.sourceNum].src)
      this.state.player.load()
      this.state.player.play()
      return
    }
    if (
      prevProps.sources[prevProps.sourceNum].src !==
      this.props.sources[this.props.sourceNum].src
    ) {
      this.state.player.setSrc(this.props.sources[this.props.sourceNum].src)
      this.state.player.load()
      this.state.player.play()
    }
  }

  mediaAction(e) {
    let player = this.state.player
    switch (e) {
      case 'play':
        player.play()
        break
      case 'pause':
        player.pause()
        break
      case '30f':
        player.setCurrentTime(
          player.duration - player.getCurrentTime() < 30
            ? player.duration - 0.01
            : player.getCurrentTime() + 30
        )
        break
      case '30b':
        player.setCurrentTime(
          player.getCurrentTime() < 30 ? 0.01 : player.getCurrentTime() - 30
        )
        break
      case 'mute':
        player.setMuted(true)
        break
      case 'unmute':
        player.setMuted(false)
        break
      case 'back':
        player.getCurrentTime() > 1.2 || this.props.sourceNum === 0
          ? player.setCurrentTime(0.01)
          : this.props.controlState('back')
        break
    }
  }

  mediaSeek(e) {
    this.state.player.setCurrentTime((e * this.state.player.duration) / 1)
  }

  onTimeUpdate(e) {
    if (e.currentTime / e.duration > 0.66 && !this.state.playedEighty) {
      ReactGA.ga(
        'send',
        'event',
        'Listen Duration',
        `${
          this.props.sources[this.props.sourceNum].mediaInfo.title
        } - 2/3 listened`
      )
      this.setState({ playedEighty: true })
    }
    this.setState({
      progressIndex: e.currentTime / e.duration,
      timeRemaining: e.duration - e.currentTime,
      timeElapsed: e.currentTime,
    })
  }

  render() {
    const playing = this.state.playing
    const muted = this.state.muted

    const props = this.props,
      sources = props.sources,
      tracks = JSON.parse(props.tracks),
      sourceTags = [],
      tracksTags = []
    if (sources) {
      for (let i = 0, total = sources.length; i < total; i++) {
        const source = sources[i]
        sourceTags.push(`<source src="${source.src}" type="${source.type}">`)
      }

      for (let i = 0, total = tracks.length; i < total; i++) {
        const track = tracks[i]
        tracksTags.push(
          `<track src="${track.src}" kind="${track.kind}" srclang="${
            track.lang
          }"${track.label ? ` label=${track.label}` : ''}>`
        )
      }
    }

    const mediaBody = `${sourceTags.join('\n')}
        ${tracksTags.join('\n')}`,
      mediaHtml =
        props.mediaType === 'video' ? (
          <video
            id={props.id}
            poster={props.poster}
            controls={props.controls}
            preload={props.preload}>
            {mediaBody}
          </video>
        ) : (
          <audio
            ref="mediaDiv"
            onCanPlay={this.props.onLoaded}
            onPlay={e => this.props.onPlayed(e)}
            onTimeUpdate={e => this.onTimeUpdate(this.refs.mediaDiv)}
            onEnded={this.props.onEnded}
            id={props.id}
            controls={props.controls}>
            {mediaBody}
          </audio>
        )
    return (
      <div>
        <MediaPlayerControls
          onPlayState={this.props.onPlayState}
          loading={this.state.loading}
          controlState={this.props.controlState}
          progressIndex={this.state.progressIndex}
          timeRemaining={this.state.timeRemaining}
          timeElapsed={this.state.timeElapsed}
          mediaAction={this.mediaAction}
          mediaSeek={this.mediaSeek}
          actionClick={this.actionClick}
          playing={this.props.playing}
          muted={this.props.muted}
          sources={this.props.sources}
          sourceNum={this.props.sourceNum}
          playerExpanded={this.props.playerExpanded}
          expandState={this.props.expandState}
          playerLoaded={this.state.playerLoaded}
        />
        <div style={{ opacity: '0' }} className={player.hide}>
          {mediaHtml}
        </div>
      </div>
    )
  }

  componentDidMount() {
    const options = Object.assign({}, JSON.parse(this.props.options), {
      pluginPath: './static/media/',
    })

    import('mediaelement').then(MediaElement => {
      thePlayer = new MediaElementPlayer(this.props.id, options)
      this.setState({ player: thePlayer })
    })

    setTimeout(() => {
      this.setState({ loading: false })
    }, 1)
  }

  componentWillUnmount() {
    if (this.state.player) {
      this.state.player.remove()
      this.setState({ player: null })
    }
  }
}
